import React from "react";
import { Helmet } from "react-helmet";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro"; 
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;

export default ({ headingText = "Terms And Condition" }) => {
  return (
    <AnimationRevealPage disabled>
      <Helmet>
        <title>Review the Terms and Conditions before your place an order | 360 Expose</title>
        <meta name="description" content="Review and read the updated terms and conditions before placing an order." />
      </Helmet>
      <Header />
      <Container className="terms-wrapper">
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <div className="row">
            <div className="col-md-3">
              <h4>Terms of Services</h4>
            </div>
            <div className="col-md-9">
              <p>
                Digital Marketing project is accomplished and delivered by 360
                Expose Pvt Ltd.
              </p>
              <p>
                By placing an order and signing the agreement on
                www.360exposeltd.com.com online platform, you confirm that you
                agree with and are bound by the terms and conditions of 360
                Expose Pvt Ltd as below.
              </p>
              <p>
                Here, Online service means “Content Marketing”, “Search Engine
                Optimization”, “Facebook Marketing”, “Email Marketing” but not
                limited to. By signing and agreeing with the agreement form, you
                are obliged to get the online service as mentioned in the
                agreement and other than else you are required to pay extra or
                will be quoted separately, and hence agreement needs to sign up
                separately to accomplish the project.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <h4>Scope of Work </h4>
            </div>
            <div className="col-md-9">
              <p>
                The “COMPANY” shall agree to provide the development services to
                “Client” with skills, guidance, and expertise as the requirement
                of “Client”. If the scope of work changes after signing this
                Agreement, “CLIENT” and “COMPANY” agree to negotiate and work
                accordingly with updated cost and time frame.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <h4>File-Ownership</h4>
            </div>
            <div className="col-md-9">
              <p>
                After the complete handover of all the project and related
                accounts, only 3 months debugging period and file backup are
                available. After that Client has to subscribe to our plan then
                only files Backup and debugging will be maintained accordingly.
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-3">
              <h4>Payment</h4>
            </div>
            <div className="col-md-9">
              <p>
                The client payment are due no later than the respective Due Date
                specified in your invoice. No partial payment is facilitated.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <h4>Estimate</h4>
            </div>
            <div className="col-md-9">
              <p>
                Upon acceptance by you 360 Expose Pvt Ltd. will perform the
                services project schedule described in the estimate or according
                to a timeline. Any additional services requested by you are not
                covered by the estimate. This estimate is valid for a week after
                receipt of this estimate.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <h4>Invoice</h4>
            </div>
            <div className="col-md-9">
              <p>
                Once the Invoice is generated, the Client shall be payable
                within 7 days of the due date according to the payment schedule
                above unless a different date for payment has been agreed upon.
                If we grant our customers a discount, the discount amount is
                calculated based on the subtotal invoice amount (before VAT).360
                Exposse Pvt. Ltd. reserves the right to make an appropriate
                price adjustment.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <h4>Taxes</h4>
            </div>
            <div className="col-md-9">
              <p>
                0% VAT (Value Added Tax) is added. Merchant Processing fee
                (3.5%), Conversion fee, and bank wire fee (6%) will be applied
                to all online payments apart from Direct Bank Deposit,
                Remittance like Western Union, Money Gram, World Remit, etc. For
                Bank transfers and Remittances, the fees will be applied to the
                customer. The charges for the services are described in the
                estimate, subject to adjustment as provided in these terms, and
                you agree to pay such charges.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <h4>Subscription</h4>
            </div>
            <div className="col-md-9">
              <p>
                By subscribing to our Service, you are agreeing to pay all the
                charges and fees for an indefinite time until expiry. Payment
                for all services is processed via Online payment, Bank Transfer,
                Remittance. The Price of the products will be as quoted
                excluding fees and tax on our site from time to time, except in
                cases of obvious error.
              </p>
              <p>
                The user agrees to pay the amount for all subscribed plans as
                described in the contract.
              </p>
              <p>
                [Note: All third-party services like Google Maps API, SMS
                Gateway, Payment Gateway, Flight API, etc. should be payable
                from the client end.
                <br />
                All additional features will be charged on the hourly cost of US
                $10.]
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <h4>Payment Method</h4>
            </div>
            <div className="col-md-9">
              <p>
                We accept online payment - Swift Transfer, Bank Deposit,
                Remittance, Credit Card, Debit Card for the Products and
                Services.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <h4>Privacy Policy</h4>
            </div>
            <div className="col-md-9">
              <p>
                Before you continue using our website, we advise you to read our
                privacy regarding our user data collection. It will help you
                better understand our practices.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <h4>Refund Policy</h4>
            </div>
            <div className="col-md-9">
              <p>
                The Company do not offer any refund policy including
                subscription. Each service provides reserves with the right to
                offer refunds, discounts, or other considerations in select
                circumstances at its sole discretion. Please note that each
                circumstance is unique and the election to make such an offer in
                one instance does not create the obligation to do so in another.
                Once the payment is processed, the client/customer will not be
                able to cancel the transaction in any circumstance. No payment
                will be refunded to the client.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <h4>Subscription Product</h4>
            </div>
            <div className="col-md-9">
              <p>The Client can subscribe yearly and monthly</p>
              <ul>
                <li>Organic SEO (Yearly / Monthly)</li>
                <li>Google Adward (Yearly / Monthly)</li>
                <li>Social Marketing (Yearly / Monthly)</li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <h4>Customer Portal Access</h4>
            </div>
            <div className="col-md-9">
              <p>
                Customer Portal Access will be invited via email. Clients have
                to accept invitations and login into our client’s portal to view
                invoices, estimates, payment history and also can make payments
                to their current invoices via secure payment gateways.{" "}
                <a
                  href="https://subscriptions.zoho.com/portal/coredreamsinnovationspvtltd/login"
                  target="_blank"
                  rel="noreferrer"
                >
                  Click here to log in.
                </a>
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <h4>Support</h4>
            </div>
            <div className="col-md-9">
              <p>
                The client can create a ticket for further support.{" "}
                <a
                  href="https://desk.zoho.com/portal/coredreams/en/signin"
                  target="_blank"
                  rel="noreferrer"
                >
                  Click{" "}
                </a>{" "}
                here for our support.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <h4>Appointment</h4>
            </div>
            <div className="col-md-9">
              <p>
                For further consultation, the Client can schedule an
                appointment.{" "}
                <a
                  href="https://calendly.com/coredreams/appointment?month=2022-01"
                  target="_blank"
                  rel="noreferrer"
                >
                  Click here{" "}
                </a>{" "}
                to make an appointment
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <h4>Termination </h4>
            </div>
            <div className="col-md-9">
              <p>
                360 Expose Pvt Ltd holds the right to terminate the project if
                payment is delayed or no clear agreement or requirements
                beforehand.
              </p>
              <p>
                Once subscribed to the plan, the Client has no option to cancel
                the plan immediately, it will be cancelled only on the next
                renewal date. You can re-subscribe at any time. If you wish to
                cancel your subscription with us, you have to inform us 7 days
                before the subscription expiry, to avoid receiving the invoice
                for the renewal.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <h4>Complain Procedure</h4>
            </div>
            <div className="col-md-9">
              <p>
                It is always our intention to provide you with a quality
                service. However, if you have cause to complain, please contact{" "}
                <a href="mailto:info@360expose.com">info@360expose.com</a>{" "}
                addressing the problem with sufficient information in writing
                consent. We will try to resolve your concerns to your
                satisfaction as soon as possible.
              </p>
            </div>
          </div>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
