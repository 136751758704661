import React from "react";
import tw from "twin.macro";
import { Link } from 'react-router-dom'
import styled from "styled-components"; 
import {
  SectionHeading
} from "components/misc/Headings.js";  

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24 items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-6/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const Image = styled.img((props) => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`,
]);

 
const TextContent = tw.div`lg:py-8 text-center md:text-left`;
 
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
 
 

export default ({
  heading = "",
  desc = "",
  link = "",
  id="",
  imageSrc = {},
  textOnLeft = true,
}) => {
  return (
    <Container className="hp-services" id={id}>
      <TwoColumn>
        <ImageColumn>
          <Image src={imageSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Heading>{heading}</Heading> 
              <p>{desc}</p>
              <Link className="text-link" to={link}>Learn More</Link> 
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
