import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Helmet } from "react-helmet"; 
 
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
 
import Features from "components/features/ThreeColSimple.js";
 
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";
 
export default () => {
  return ( 
    <AnimationRevealPage disabled>
      <Helmet>
        <title>
        Professional SEO services - 360 Expose
        </title>
        <meta
          name="description"
          content="Our SEO service helps you generate the highest number of sales & leads for every rupee spent. Our SEO process focuses on understanding your digital marketing needs"
        />
      </Helmet>
      <Header />
      <section className="about-page">
        <div className="container">
          <h1 className="inner-main-heading section-gap mt-5">
          Digital Solutions That <br/> <span> Achieve Success</span> 
          </h1>

          <p>
          Digital marketing encompasses all marketing efforts that use an electronic device or the internet.360 Expose uses the internet, mobile devices, social media such as Facebook, search engines, display advertising, and other channels to reach consumers. We help to achieve marketing objectives through applying digital technologies and media. We help your organization attain specific goals through carefully selected marketing channels such as paid, earned, and owned media. We have a set of planned actions performed online to reach specific business goals. We implement series of actions that use online marketing channels to achieve various goals. Channels can include search engines and social media. We implement Search Engine Optimization (SEO), Pay-Per-Click (PPC) Marketing, Content Marketing.
          </p>
<p>
We help you to reach global customers at a lowers cost where you can track and measure results and helps to improve conversion rates. We assist you with more conversions without your skills and training and are time-consuming. We help you stand in the crowd in high competition with fewer complaints and feedback.360 Expose helps you to develop your brand to global customers with precise targeting and greater engagement.   </p>
        </div>
      </section>

      <Features
        heading="We follow these."
        description="Always striving to establish a “one team” mentality with our clients and partners working towards a common goal."
        cards={[
          {
            imageSrc: SupportIconImage,
            title: "Support",
            description: "Remote Phone, Email support from our expertise.",
          },
          {
            imageSrc: ShieldIconImage,
            title: "Strong Teams",
            description: "Passionate and Highly skilled team are available.",
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "Customer Satisfaction",
            description:
              "We are always at the top in terms of client satisfaction",
          },
        ]}
        linkText=""
      />
      <Testimonial
        heading={<>Our Clients</>}
        testimonials={[
          {
            stars: 5,
            heading: "Great Service",
            quote:
              "Great Service. Helped a lot for my website. Definitely would recommend to others!!",
            customerName: "Selliah Wigneswaran",
          },
          {
            stars: 4,
            heading: "I have been using them for over 5 year",
            quote:
              "I have been using them for over 5 year. They have excellent understanding of technology required to do the job. They have a yes attitude and to top it up they always thoroughly understand the business model to make the technology work. Will recommend them.",
            customerName: "Abdullah Malik",
          },
          {
            stars: 5,
            heading: "Please. with the site.",
            quote:
              " Hi, very professional website builder. I’ve my latest site builded my them and I’m very please with It. I would recommend them, very good thanks.",
            customerName: "Jj appleby",
          },
          {
            stars: 5,
            heading: "Perfect Website, they help my succeed",
            quote:
              "They build my website I really love, also when something needs to be done or changed they always help you very fast. I run a bussiness so speed is very important. I give them 10/10.",
            customerName: "Nordin Rhninou",
          },
          {
            stars: 5,
            heading: "Sunil and his team at Taxiweb Design…",
            quote:
              " Sunil and his team at Taxiweb Design built a great taxi booking website for me. I'm impressed by how good they translated my vision into reality at the end creating a beautiful website! In my experience working with them, they were professional, prompt in responding and affordable.         Ali rideonemn.com",
          },
          {
            customerName: "Carresa Group",
            stars: 5,
            heading: "Thank you team taxi web design for your…",
            quote:
              "Thank you team taxi web design for your service and for your support",
          },
          {
            customerName: "Othman Kadiri",
            stars: 5,
            heading: "Very reactive team",
            quote:
              "Very reactive team. We worked on a bus company website with Sunil and Anil and everything went very smoothly. A very professional team, friendly and always reactive to fix any problem. I recommend !",
          },
          {
            customerName: "Chi",
            stars: 5,
            heading: "Very professional, and awesome to work with.",
            quote:
              "I have been working closely with Sunil and his guys since January 2020 to design a custom web website and a booking system for my car service business website. I must tell you, these guys understand web development, and are very patient with their customers. They have done a wonderful job, and I will be using them for every web development project that I can not do myself. Thank you guys!",
          },
        ]}
      />
      <Footer />
    </AnimationRevealPage>
  );
};
