import React from "react"; 
import tw from "twin.macro";  
import {  Container } from "components/misc/Layouts";
 
const Row = tw.div`px-8 max-w-screen-lg mx-auto flex items-center relative z-10 flex-col lg:flex-row text-center lg:text-left`;

const ColumnContainer = tw.div`lg:w-1/2 max-w-lg`;
const TextContainer = tw(ColumnContainer)``;
const Text = tw.h5`text-gray-100 text-2xl sm:text-3xl font-bold`;

const LinksContainer = tw(
  ColumnContainer
)`flex justify-center lg:justify-end mt-6 lg:mt-0 flex-col sm:flex-row`;

const Link = tw.a`w-full sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-5 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0 rounded-full font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
const PrimaryLink = tw(
  Link
)`bg-red-500 text-gray-100 shadow-lg hocus:bg-red-600 hocus:text-gray-200`;
 
export default ({
  text = "Get Your Free Consultation Now ",
  primaryLinkText = "Book Your Consultation",
  primaryLinkUrl = "https://calendly.com/coredreams/appointment",
}) => {
  return (
    <Container className="hp-consultation">
      <Row>
        <TextContainer>
          <Text>
            {text}
            <h6>
             Lets Discuss Your  Business Requirements.
            </h6>
          </Text>
        </TextContainer>
        <LinksContainer>
          <div className="slant-btn">
            <PrimaryLink href={primaryLinkUrl} target="_blank">
              {primaryLinkText}
            </PrimaryLink>
          </div>
        </LinksContainer>
      </Row>
    </Container>
  );
};
