import React from "react";
import { Helmet } from "react-helmet";
import Modal from "react-modal";
import ReCAPTCHA from "react-google-recaptcha";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";

import seoFlow from "images/seo.png";
import seoProcess from "images/seo-process.png";
export default () => {
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#121212";
  }

  function closeModal() {
    setIsOpen(false);
  }

  const imgCss = {
    width: "40%",
    margin: "0 auto",
    marginTop: "50px",
  };
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };
  function rccallback2806704000033917009() {
    if (document.getElementById("recap2806704000033917009") != undefined) {
      document
        .getElementById("recap2806704000033917009")
        .setAttribute("captcha-verified", true);
    }
    if (
      document.getElementById("recapErr2806704000033917009") != undefined &&
      document.getElementById("recapErr2806704000033917009").style.visibility ==
        "visible"
    ) {
      document.getElementById("recapErr2806704000033917009").style.visibility =
        "hidden";
    }
  }

  function reCaptchaAlert2806704000033917009() {
    var recap = document.getElementById("recap2806704000033917009");
    if (
      recap != undefined &&
      recap.getAttribute("captcha-verified") == "false"
    ) {
      document.getElementById("recapErr2806704000033917009").style.visibility =
        "visible";
      return false;
    }
    return true;
  }

  function validateEmail2806704000033917009() {
    var form = document.forms["WebToContacts2806704000033917009"];
    var emailFld = form.querySelectorAll("[ftype=email]");

    for (var i = 0; i < emailFld.length; i++) {
      var emailVal = emailFld[i].value;
      if (emailVal.replace(/^\s+|\s+$/g, "").length != 0) {
        var atpos = emailVal.indexOf("@");
        var dotpos = emailVal.lastIndexOf(".");
        if (atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= emailVal.length) {
          alert("Please enter a valid email address. ");
          emailFld[i].focus();
          return false;
        }
      }
    }
    return true;
  }

  function checkMandatory2806704000033917009() {
    var mndFileds = new Array("Last Name", "Email", "Phone", "CONTACTCF5");
    var fldLangVal = new Array(
      "Full\x20Name",
      "Email",
      "Mobile",
      "Company\x20Name"
    );
    for (var i = 0; i < mndFileds.length; i++) {
      var fieldObj =
        document.forms["WebToContacts2806704000033917009"][mndFileds[i]];
      if (fieldObj) {
        if (fieldObj.value.replace(/^\s+|\s+$/g, "").length == 0) {
          if (fieldObj.type == "file") {
            alert("Please select a file to upload.");
            fieldObj.focus();
            return false;
          }
          alert(fldLangVal[i] + " cannot be empty.");
          fieldObj.focus();
          return false;
        } else if (fieldObj.nodeName == "SELECT") {
          if (fieldObj.options[fieldObj.selectedIndex].value == "-None-") {
            alert(fldLangVal[i] + " cannot be none.");
            fieldObj.focus();
            return false;
          }
        } else if (fieldObj.type == "checkbox") {
          if (fieldObj.checked == false) {
            alert("Please accept  " + fldLangVal[i]);
            fieldObj.focus();
            return false;
          }
        }
        try {
          if (fieldObj.name == "Last Name") {
            var name = fieldObj.value;
          }
        } catch (e) {}
      }
    }
    trackVisitor2806704000033917009();
    if (!validateEmail2806704000033917009()) {
      return false;
    }

    if (!reCaptchaAlert2806704000033917009()) {
      return false;
    }
    document
      .querySelector(".crmWebToEntityForm .formsubmit")
      .setAttribute("disabled", true);
  }

  function tooltipShow2806704000033917009(el) {
    var tooltip = el.nextElementSibling;
    var tooltipDisplay = tooltip.style.display;
    if (tooltipDisplay == "none") {
      var allTooltip = document.getElementsByClassName("zcwf_tooltip_over");
      for (var i = 0; i < allTooltip.length; i++) {
        allTooltip[i].style.display = "none";
      }
      tooltip.style.display = "block";
    } else {
      tooltip.style.display = "none";
    }
  }

  var $zoho = $zoho || {};
  $zoho.salesiq = $zoho.salesiq || {
    widgetcode:
      "82497d4fb5fe6d4fd542d0183e11e76db122a1bf9a5945e27ac5ef4a65299b30",
    values: {},
    ready: function () {},
  };
  var d = document;
  var s = d.createElement("script");
  s.id = "zsiqscript";
  s.defer = true;
  s.src = "https://salesiq.zoho.com/widget";
  var t = d.getElementsByTagName("script")[0];
  t.parentNode.insertBefore(s, t);

  function trackVisitor2806704000033917009() {
    try {
      if ($zoho) {
        var LDTuvidObj =
          document.forms["WebToContacts2806704000033917009"]["LDTuvid"];
        if (LDTuvidObj) {
          LDTuvidObj.value = $zoho.salesiq.visitor.uniqueid();
        }
        var firstnameObj =
          document.forms["WebToContacts2806704000033917009"]["First Name"];
        if (firstnameObj) {
          var name = firstnameObj.value + " " + name;
        }
        $zoho.salesiq.visitor.name(name);
        var emailObj =
          document.forms["WebToContacts2806704000033917009"]["Email"];
        if (emailObj) {
          var email = emailObj.value;
          $zoho.salesiq.visitor.email(email);
        }
      }
    } catch (e) {}
  }
  return (
    <AnimationRevealPage disabled>
      <Helmet>
        <title>
          SEO Service at Best Price from SEO Experts - Marketing to Next Level
        </title>
        <meta
          name="description"
          content="360 Expose  provides best SEO Service. We provide best On-page SEO, Off-page SEO and Technical SEO with marketing to next level."
        />
        <script
          id="wf_anal"
          src="https://crm.zohopublic.com/crm/WebFormAnalyticsServeServlet?rid=6472df18ec48439af15916fc12450927840b1ede78ea83318627998c619776aagidced1872ed3f0e7a57f85c65540721b18cdc993ff69dd8fd48681248af41ba5a1gid5cf99212d7958dbcd176c761c6ce0a4d7e43e277bdc5d8b9c12010015418942cgid14f4ec16431e0686150daa43f3210513&tw=61690b96c1d0471b638f31426f38e68aa67fb7ed6da86f32dc10ad817fe55a0a"
        ></script>
      </Helmet>
      <Header />
      <section className="service-page mb-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h1 className="inner-main-heading">
                Search Engine <span>Optimization</span>
              </h1>
              <p>
                Search engine marketing (SEM) is a digital marketing strategy
                used to increase the visibility of a website on search engine
                results pages (SERPs). It is a form of Internet marketing that
                involves the promotion of websites by increasing their
                visibility in search engines. It is an act of using paid
                strategies to increase search visibility. It helps to increase
                your website or app visibility in the search engines with
                different marketing strategies. We help your business to reach
                the targeted customers with the marketing that builds your
                customer base and increases revenue. We implement different
                White Hat marketing platforms to reach the top of the search
                engine and showcase your product and services.
              </p>
              <img className="mb-5" src={seoFlow} style={imgCss} />
              <h3>Search Engine Marketing</h3>
              <p>
                Our SEO strategy improves the quality and quantity of website
                traffic to a website or a web page from search engines.
              </p>
              <img
                className="mb-5"
                src={seoProcess}
                style={{ width: "25%", margin: "0 auto", marginTop: "50px" }}
              />
              <h5> SEO Action Plan </h5>
              <p>
                To accomplish the above tasks properly and ensure that we have
                performed our audit as thoroughly as possible to provide you
                with the best results, we will require about 6 weeks. The table
                below breaks down the timeline by each portion of the SEO.
              </p>
              <h5>Keyword Analysis</h5>
              <p>
                Find profitable keywords with search intent so that we can focus
                more organic traffic in our targeted locations
              </p>
              <br></br>
              <ul className="col-count-2 text-left">
                <li>Low Competitive</li>
                <li>High Demanding Keyword</li>
                <li>Focusing Keywords Related Keywords</li>
              </ul>
              <h5> Content Analysis</h5>

              <ul className="col-count-2 text-left">
                <li> Content Quality</li>
                <li> Search Engine Friendly Content</li>
                <li>User Friendly Content </li>
                <li>Update Content </li>
                <li>Keyword Focusing Content </li>
              </ul>
              <h3>Google Search Console Setup</h3>
              <h5>Google Analytics Setup</h5>
              <h5>Technical SEO</h5>

              <ul className="col-count-2 text-left">
                <li>Crawling Fix</li>
                <li>Indexing Fix</li>
                <li>Page Speed Optimization</li>
                <li>Site Speed Setup</li>
                <li>URL Management</li>
                <li>Redirection Setup( If Need)</li>
                <li>Canonical Tag Setup</li>
                <li>Schema Markup</li>
                <li>Duplicate Content Issue</li>
                <li>Sitemap Setup</li>
                <li>Robots.txt Setup</li>
                <li>Website Submit to search Console</li>
                <li>Meta Robots Tag Setup</li>
                <li>Malware Removal- On request </li>
              </ul>
              <h5>On Page SEO </h5>
              <ul className="col-count-2 text-left">
                <li>Urls Optimization</li>
                <li>Title </li>
                <li>Meta Descriptions</li>
                <li>Content Optimization</li>
                <li>Heading Optimization</li>
                <li>Subheading Optimization</li>
                <li>Image Alt Text</li>
                <li>Internal Links</li>
                <li>External Links</li>
                <li>Keyword Density</li>
                <li>Keyword Stuffing</li>
                <li>Keyword Cannibalization</li>
                <li>Landing Page</li>
              </ul>
              <h5>External SEO </h5>
              <ul className="col-count-2 text-left">
                <li>Decrease Bounce rate</li>
                <li>Traffic Monitor</li>
              </ul>
              <h5>Off Page SEO (Link Building) </h5>
              <ul className="col-count-2 text-left">
                <li>Blog Writing</li>
                <li>Links From Niche Relevant High Authority Sites</li>
                <li>Guest Post Backlinks</li>
                <li>Anchor Text</li>
                <li>High Authority Backlinks</li>
                <li>Permanent Links</li>
                <li>Manual Link Building</li>
                <li>Tier1,Tier2.Tier3 Backlinks</li>
                <li>Social Share Backlinks</li>
              </ul>
              <button onClick={openModal} className="btn-rounded mt-5 ">
                Order Now
              </button>
              <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
              >
                <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Order Now</h2>
                <button
                  style={{
                    position: "absolute",
                    top: "5px",
                    right: "20px",
                    fontSize: "25px",
                    color: "#c61919",
                  }}
                  onClick={closeModal}
                >
                  x
                </button>
                <div
                  id="crmWebToEntityForm"
                  class="zcwf_lblLeft crmWebToEntityForm"
                >
                  <form
                    action="https://crm.zoho.com/crm/WebToContactForm"
                    name="WebToContacts2806704000033917009"
                    method="POST"
                    onSubmit='javascript:document.charset="UTF-8"; return checkMandatory2806704000033917009()'
                    accept-charset="UTF-8"
                  >
                    <input
                      type="text"
                      style={{ display: "none" }}
                      name="xnQsjsdp"
                      value="ced1872ed3f0e7a57f85c65540721b18cdc993ff69dd8fd48681248af41ba5a1"
                    ></input>
                    <input
                      type="hidden"
                      name="zc_gad"
                      id="zc_gad"
                      value=""
                    ></input>
                    <input
                      type="text"
                      style={{ display: "none" }}
                      name="xmIwtLD"
                      value="6472df18ec48439af15916fc12450927840b1ede78ea83318627998c619776aa"
                    ></input>
                    <input
                      type="text"
                      style={{ display: "none" }}
                      name="actionType"
                      value="Q29udGFjdHM="
                    ></input>
                    <input
                      type="text"
                      style={{ display: "none" }}
                      name="returnURL"
                      value="https&#x3a;&#x2f;&#x2f;360expose.com&#x2f;thank-you"
                    ></input>

                    <input
                      type="text"
                      style={{ display: "none" }}
                      id="ldeskuid"
                      name="ldeskuid"
                    ></input>
                    <input
                      type="text"
                      style={{ display: "none" }}
                      id="LDTuvid"
                      name="LDTuvid"
                    ></input>

                    <div class="zcwf_row">
                      <div class="zcwf_col_lab">
                        <label for="Last_Name">
                          Full Name<span style={{ color: "red" }}>*</span>
                        </label>
                      </div>
                      <div class="zcwf_col_fld">
                        <input
                          type="text"
                          id="Last_Name"
                          name="Last Name"
                          maxlength="80"
                        ></input>
                        <div class="zcwf_col_help"></div>
                      </div>
                    </div>
                    <div class="zcwf_row">
                      <div class="zcwf_col_lab">
                        <label for="CONTACTCF5">
                          Company Name<span style={{ color: "red" }}>*</span>
                        </label>
                      </div>
                      <div class="zcwf_col_fld">
                        <input
                          type="text"
                          id="CONTACTCF5"
                          name="CONTACTCF5"
                          maxlength="255"
                        ></input>
                        <div class="zcwf_col_help"></div>
                      </div>
                    </div>
                    <div class="zcwf_row">
                      <div class="zcwf_col_lab">
                        <label for="Email">
                          Email<span style={{ color: "red" }}>*</span>
                        </label>
                      </div>
                      <div class="zcwf_col_fld">
                        <input
                          type="text"
                          ftype="email"
                          id="Email"
                          name="Email"
                          maxlength="100"
                        ></input>
                        <div class="zcwf_col_help"></div>
                      </div>
                    </div>
                    <div class="zcwf_row">
                      <div class="zcwf_col_lab">
                        <label for="Phone">
                          Mobile<span style={{ color: "red" }}>*</span>
                        </label>
                      </div>
                      <div class="zcwf_col_fld">
                        <input
                          type="text"
                          id="Phone"
                          name="Phone"
                          maxlength="50"
                        ></input>
                        <div class="zcwf_col_help"></div>
                      </div>
                    </div>
                    <div class="zcwf_row">
                      <div class="zcwf_col_lab">
                        <label for="CONTACTCF6">
                          Website &#x28;if any &#x29;
                        </label>
                      </div>
                      <div class="zcwf_col_fld">
                        <input
                          type="text"
                          id="CONTACTCF6"
                          name="CONTACTCF6"
                          maxlength="450"
                        ></input>
                        <div class="zcwf_col_help"></div>
                      </div>
                    </div>
                    <div class="zcwf_row">
                      <div class="zcwf_col_lab"></div>
                      <div class="zcwf_col_fld">
                        <ReCAPTCHA sitekey="6Ld8QpMgAAAAAOCy24CZgBx0C5uSvZPbeENKNVgg" />
                      </div>
                    </div>
                    <div class="zcwf_row">
                      <div class="zcwf_col_lab"></div>
                      <div class="zcwf_col_fld">
                        <input
                          type="submit"
                          id="formsubmit"
                          class="formsubmit zcwf_button"
                          value="Submit"
                          title="Submit"
                        />
                        <input
                          type="reset"
                          class="zcwf_button"
                          name="reset"
                          value="Reset"
                          title="Reset"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </AnimationRevealPage>
  );
};
