import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, Content2Xl } from "components/misc/Layouts";
import tw from "twin.macro";
import GitHubButton from "react-github-btn";
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { SectionHeading as HeadingBase } from "components/misc/Headings";
import { SectionDescription as DescriptionBase } from "components/misc/Typography";

/* Hero */
const Row = tw.div`flex`;

const HeroRow = tw(
  Row
)`max-w-xl flex-col justify-between items-center py-20 lg:py-24 mx-auto`;

const Heading = tw(HeadingBase)`text-center text-primary-900 leading-snug`;

export default () => {
  /*
   * Using gtag like this because we only want to use Google Analytics when Main Landing Page is rendered
   * Remove this part and the the gtag script inside public/index.html if you dont need google analytics
   */

  return (
    <AnimationRevealPage disabled>
      <Header />
      <Container tw="-mx-8 -mt-8 pt-8 px-8">
        <HeroRow>
          <Heading style={{ color: "#C43037" }}>Thank You!</Heading>

          <div tw="mt-12 text-center">
            Your request has been submitted successfully. We will contact you
            soon.
          </div>
        </HeroRow>
      </Container>

      <Footer />
    </AnimationRevealPage>
  );
};
