import React, { useRef } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import emailjs from '@emailjs/browser';

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;

const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`;
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`;
const Textarea = styled(Input).attrs({ as: "textarea" })`
  ${tw`h-24`}
`;

const SubmitButton = tw(PrimaryButtonBase)`inline-block  mt-8`;

var heading = <>Send Us a Message</>
var submitButtonText = "Send"
var formAction = "#"
var formMethod = "get"
var textOnLeft = true
export const ContactUs = () => {
  var formData = { 'name': '', 'email': '', 'subject': '', 'message': '' };
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    var isValid = false;
    Object.keys(formData).map(function (key) {
      if (!formData[key]) {
        isValid = true;
      }
    });

    if (isValid) {
      alert('Please fill all the fileds.');
      return false;
    }
    emailjs.sendForm("service_mxnvofa", "template_l2o7zqf", form.current, 'MXYIhyr3yDLOENpcr')
      .then((result) => {
        e.target.reset();
        alert("Email sent successfully.");
      }, (error) => {
        alert("Something went wrong. Failed to sent email.");
      });
  };

  const setValue = (name, value) => {
    formData[name] = value;
  }

  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  return (
    <Container className="contact-form-wrapper">
      <TwoColumn>
        <TextColumn>
          <h3 className="mt-5">Contact Information</h3>
          <p>
            We have offices below address that provides product-related customer
            services
          </p>
          <br />
          <h5>Kathmandu, Nepal 44700</h5>

          <a href="tel:+977015543015">Nepal: +977 (01) 5543015 </a>
          <a href="tel:+17186184363">USA: +1 (718) 618-4363 </a>

          <a href="mailto:info@360expose.com">info@360expose.com</a>

          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3533.8949850531626!2d85.31473901744384!3d27.6587206!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb19d5889c18a1%3A0xf876386435669c81!2sCore%20Dreams%20Innovation%20Pvt.%20Ltd!5e0!3m2!1sen!2snp!4v1653285356221!5m2!1sen!2snp"
            width="100%"
            height="150px"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </TextColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Heading>{heading}</Heading>

            <Form ref={form} action={formAction} method={formMethod} onSubmit={sendEmail}>
              <Input type="text" name="name" placeholder="Full Name" onChange={event => setValue(event.target.name, event.target.value)} />
              <Input type="email" name="email" placeholder="Your Email Address" onChange={event => setValue(event.target.name, event.target.value)} />
              <Input type="text" name="subject" placeholder="Subject" onChange={event => setValue(event.target.name, event.target.value)} />
              <Textarea name="message" placeholder="Your Message Here" onChange={event => setValue(event.target.name, event.target.value)} />
              <SubmitButton type="submit">{submitButtonText}</SubmitButton>
            </Form>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
export default ContactUs;