import React from "react";
import tw from "twin.macro";
import styled from "styled-components";  
import coreLogoImage from "images/core-logo.svg";
import taxiLogoImage from "images/taxilogo.png";

import { ReactComponent as FacebookIcon } from "images/facebook-logo.svg";
import { ReactComponent as LinkedInIcon } from "images/linkedin.svg";
import { Link } from 'react-router-dom'
const Container = tw.div `relative bg-gray-200 text-gray-700 -mb-8 -mx-8 px-8 py-20 lg:py-24`;
const Content = tw.div `max-w-screen-xl mx-auto relative z-10`;
 
const Column = tw.div `px-4 sm:px-0 sm:w-1/4 md:w-auto mt-12`;

const ColumnHeading = tw.h5 `uppercase font-bold`;

const LinkList = tw.ul `mt-6 text-sm font-medium`;
const LinkListItem = tw.li `mt-3`;
 
const ThreeColRow = tw.div `flex flex-col md:flex-row items-center justify-between`;
 
const LogoImg = tw.img `w-8`;

const CopywrightNotice = tw.p `text-center text-sm sm:text-base mt-8 md:mt-0 font-medium text-gray-500`;

const SocialLinksContainer = tw.div `mt-8 md:mt-0 flex`;
const SocialLink = styled.a `
  ${tw`cursor-pointer p-2 rounded-full  text-gray-100   transition duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

export default () => {
  return (
    <Container className="footer">
      <div className="dashed"> </div>
      <Content>
        <ThreeColRow>
          <Column>
            <ColumnHeading>QUICK LINKS</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link to="/about">About</Link>
              </LinkListItem>
              <LinkListItem>
                <Link to="/career">Career</Link>
              </LinkListItem>
              <LinkListItem>
                <Link to="/contact">Contact</Link>
              </LinkListItem>
              <LinkListItem>
                <Link to="/terms-conditions">Terms & Condition</Link>
              </LinkListItem>
              <LinkListItem>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>OUR SERVICES</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link to="/facebook-marketing">Facebook Marketing</Link>
              </LinkListItem> 
              <LinkListItem>
                <Link to="/search-engine-marketing">Search Engine Marketing</Link>
              </LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>Contact</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="javascript:void()">Kathmandu, Nepal 44700</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="mailto:info@360expose.com">
                  info@360expose.com
                </Link>
              </LinkListItem>
              {/* 
              <LinkListItem>
                <Link href="tel:+4402081332380">UK: (+44) 0208 133 2380 </Link>
              </LinkListItem> */}
              <LinkListItem>
                <a href="tel:+977015543015">Nepal: +977 (01) 5543015 </a>
              </LinkListItem>
              <LinkListItem>
                <a href="tel:+17186184363">USA: +1 (718) 618-4363 </a>
              </LinkListItem>

              {/* <LinkListItem>
                <Link href="tel:+61390154091">AUS: (+61) 3 9015 4091 </Link>
              </LinkListItem> */}
            </LinkList>
          </Column>
        </ThreeColRow>

        <ThreeColRow className="copyright-section">
          <div>
            <p className="mb-2">Our Partners <br/></p>
            <a href="https://coredreamsltd.com/" target="_blank" rel="noreferrer">
              <LogoImg className="footer-logo sm" src={coreLogoImage} /> &nbsp;
            </a>
            <a href="https://taxi-webdesign.com/" target="_blank" rel="noreferrer">
              <LogoImg className="footer-logo" src={taxiLogoImage} /> &nbsp;
            </a>
          </div>
          <CopywrightNotice>
            &copy; {new Date().getFullYear()}. 360 Expose. All Rights
            Reserved.
          </CopywrightNotice>
          <SocialLinksContainer>
            <SocialLink
              href="https://www.facebook.com/360expose"
              target="_blank"
            >
              <FacebookIcon />
            </SocialLink>
            <SocialLink
              href="https://www.linkedin.com/company/360expose/"
              target="_blank"
            >
              <LinkedInIcon />
            </SocialLink>
          </SocialLinksContainer>
        </ThreeColRow>
      </Content>
    </Container>
  );
};