import React from "react";
import { Helmet } from "react-helmet";
import AnimationRevealPage from "helpers/AnimationRevealPage.js"; 
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js"; 
import ContactImage from "images/contact.svg";
 
export default () => {
  return (
    <AnimationRevealPage disabled>
      <Helmet>
        <title>Quick Contact form for next level to start getting business | 360 Expose</title>
        <meta
          name="description"
          content="Kindly please fill up the quick contact form and our team will get back to you with more details how we can optimize your business in the online market."
        />
      </Helmet>
      <Header />
      <section className="contact-page">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h1 className="inner-main-heading text-left">
                Feel free to <span>get in touch</span> with us.
              </h1>
              <p>
              Contact Us. By Phone. (Monday to Friday, 9 am to 6 pm NST)
              </p>
            </div>
            <div className="col-md-6">
              <img alt="img" src={ContactImage} />
            </div>
          </div>
        </div>
      </section>
      <ContactUsForm />

      <Footer />
    </AnimationRevealPage>
  );
};
