import React from "react";
 import tw from "twin.macro"; 
import Header from "../headers/light.js";
 import BannerImg from "../../images/banner-img.svg";

const Container = tw.div`relative`;
 
const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-900 leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;
export default ({
  
  descriptions = [
    "Should I hire an SEO company or do my SEO? This is the most obvious question that you can come from your end because doing SEO or digital marketing cost you much more money than excepted.",
    "360 Expose does it all for you right from auditing the website to the end of the work.360 Expose is the professionals you might be looking for. We are based in Kathmandu with a team of 5+ experienced ready to be accompanied for your projects.",
  ],
}) => {
  return (
    <>
      <Header />
      <Container className="text-center hp-banner">
        <Heading>
        Agency for Marketing <br/> <span> Best Digital Marketing Company </span>
        
        </Heading>
        <Paragraph>
        We help you to promote brands to connect with potential customers using the internet and other forms of digital communication.
        </Paragraph>
        <div className="banner-img-wrapper">
          <img src={BannerImg} alt="image" />
        </div>
        <section className="hp-robust">
          <h2>Rising Digital <span className="highlightcolor">Marketing Agency</span></h2>
          {descriptions.map((description) => (
            <p>{description} </p>
          ))}
        </section>
        <p></p>
      </Container>
    </>
  );
};
