import React from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components"; 
import { Helmet } from "react-helmet";
import useAnimatedNavToggler from "../../helpers/useAnimatedNavToggler.js";

import logo from "images/logo.png";
import logoMini from "images/logo-mini.png";
import { ReactComponent as MenuIcon } from "feather-icons/dist/icons/menu.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";

const Header = tw.header`
  flex justify-between items-center
  max-w-screen-xl mx-auto
`;

export const NavLinks = tw.div`inline-block`;

/* hocus: stands for "on hover or focus"
 * hocus:bg-primary-700 will apply the bg-primary-700 class on hover or focus
 */
export const NavLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500
`;

export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-primary-500 text-gray-100
  hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline
  border-b-0
`;

export const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0!`};

  img {
    ${tw`w-10 mr-3`}
  }
`;

export const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-between`;
export const NavToggle = tw.button`
  lg:hidden z-20 focus:outline-none hocus:text-primary-500 transition duration-300
`;
export const MobileNavLinks = motion(styled.div`
  ${tw`lg:hidden z-10 fixed top-0 inset-x-0 mx-4 my-6 p-8 border text-center rounded-lg text-gray-900 bg-white`}
  ${NavLinks} {
    ${tw`flex flex-col items-center`}
  }
`);

export const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 justify-between items-center
`;

export default ({
  roundedHeaderButton = false,
  logoLink,
  links,
  className,
  collapseBreakpointClass = "lg",
}) => {
  // const defaultLinks = [
  //   <NavLinks key={1}>
  //     <NavLink href="/#">About</NavLink>
  //     <NavLink href="/#">Blog</NavLink>
  //     <NavLink href="/#">Pricing</NavLink>
  //     <NavLink href="/#">Contact Us</NavLink>
  //     <PrimaryLink css={roundedHeaderButton && tw`rounded-full`} href="/#">
  //       Sign Up
  //     </PrimaryLink>
  //   </NavLinks>,
  // ];
  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="/about">About</NavLink> 
      <div class="dropdown">
        <span>Services</span>
        <div class="dropdown-content">
        <NavLink href="/facebook-marketing">Facebook Marketing</NavLink>
        
        <NavLink href="/search-engine-marketing">Search Engine Marketing</NavLink>
        </div>
      </div>
      <NavLink href="/career">Career</NavLink>
      <NavLink href="/contact">Contact Us</NavLink>
    </NavLinks>,
    <NavLinks key={2}>
      <PrimaryLink
        className="btn-rounded"
        href="https://calendly.com/coredreams/appointment"
        target="_blank"
      >
        Book Your Consultation
      </PrimaryLink>
    </NavLinks>,
  ];
  const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler();
  const collapseBreakpointCss =
    collapseBreakPointCssMap[collapseBreakpointClass];

  const defaultLogoLink = (
    <LogoLink href="/">
      <img className="header-logo" src={logo} alt="logo" />
      <img className="header-mini-logo" src={logoMini} alt="logo" />
    </LogoLink>
  );

  logoLink = logoLink || defaultLogoLink;
  links = links || navLinks;

  return (
    <>
    <Helmet> 
        <script id="">
          var subscribersSiteId='f155c50d-983d-4cae-9eed-cd8e4307cdbb';</script>
          <script  src="https://cdn.subscribers.com/assets/subscribers.js"></script>

          <script id="Cookiebot" src="https://consent.cookiebot.com/uc.js" data-cbid="cba534f3-32eb-42ee-a51f-ee94f8f000cf" data-blockingmode="auto" type="text/javascript"></script>
          <script id="CookieDeclaration" src="https://consent.cookiebot.com/cba534f3-32eb-42ee-a51f-ee94f8f000cf/cd.js" type="text/javascript" async></script>
      </Helmet>
    <Header className={className || "header-light"}>
      <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
        {logoLink}
        {links}
      </DesktopNavLinks>

      <MobileNavLinksContainer
        css={collapseBreakpointCss.mobileNavLinksContainer}
      >
        {logoLink}
        <MobileNavLinks
          initial={{ x: "150%", display: "none" }}
          animate={animation}
          css={collapseBreakpointCss.mobileNavLinks}
        >
          {links}
        </MobileNavLinks>
        <NavToggle
          onClick={toggleNavbar}
          className={showNavLinks ? "open" : "closed"}
        >
          {showNavLinks ? (
            <CloseIcon tw="w-6 h-6" />
          ) : (
            <MenuIcon tw="w-6 h-6" />
          )}
        </NavToggle>
      </MobileNavLinksContainer>
    </Header>
    </>
  );
};

/* The below code is for generating dynamic break points for navbar.
 * Using this you can specify if you want to switch
 * to the toggleable mobile navbar at "sm", "md" or "lg" or "xl" above using the collapseBreakpointClass prop
 * Its written like this because we are using macros and we can not insert dynamic variables in macros
 */

const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:hidden`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:hidden`,
  },
  md: {
    mobileNavLinks: tw`md:hidden`,
    desktopNavLinks: tw`md:flex`,
    mobileNavLinksContainer: tw`md:hidden`,
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
  xl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
};
