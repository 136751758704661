import React from "react";
import { Helmet } from "react-helmet";
import AnimationRevealPage from "helpers/AnimationRevealPage.js"; 
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";    

import CareerBanner from "images/career.svg";
import WhyJoin from "images/career2.svg";
 
export default () => {
  return (
    <AnimationRevealPage disabled>
      <Helmet>
        <title>
        Digital Marketing career and scope in the market - 360 Expose
        </title>
        <meta
          name="description"
          content="With all these skills you can choose from a wide variety of jobs. Digital marketing is generally a separate department with the freedom to operate from anywhere."
        />
      </Helmet>
      <Header />
      <section className="career-page">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h1 className="inner-main-heading text-left">
                Join Our Team <span>Career Growth</span>
              </h1>
              <p className="mb-5 pb-5">
                For continuous growth, we believe in teamwork and taking on
                challenging projects. If you are committed, dedicated, and
                hardworking, and looking for Career and Knowledge Growth, then
                you might be the right candidate.
              </p>
            </div>
            <div className="col-md-6">
              <img src={CareerBanner} />
            </div>
          </div>
          <div className="row pb-5 mb-5">
            <div className="col-md-6">
              <img src={WhyJoin} />
            </div>
            <div className="col-md-6 pt-5">
              <h2 className="inner-sm-header  pt-5 text-left">Why Join Us</h2>
              <p>
                We offers you a world of potential.We ​​​encourage
                our people to dive in, roll up their sleeves and take on the
                many opportunities bound to come their way.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="hav-banner career-list section-gap">
        <div className="container">
          <div className="row">
            <div className="col-md-6 pr-4 d-flex j">
              <div>
                <h3 className="inner-sm-header">
                  Recent <br /> Vacancies
                </h3>
                <p>
                  We invest in our employees to help them develop and achieve
                  their professional goals.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <ul className="list-unstyled">
                
                <li>
                  <h4>
                  Social Marketing Specialist
                    <a
                      target="_blank"
                      rel="noreferrer"
                      className="btn btn-red float-right"  
                      href="https://coredreamsltd.zohorecruit.com/jobs/Careers/455426000001418157/Social-Marketing-Specialist?source=CareerSite"
                    >
                      Apply Now
                    </a>
                  </h4>
                </li>
                <li>
                  <h4>
                  Organic SEO Marketing Specialist
                    <a
                      target="_blank"
                      rel="noreferrer"
                      className="btn btn-red float-right"  
                      href="https://coredreamsltd.zohorecruit.com/jobs/Careers/455426000001418017/SEO?source=CareerSite"
                    >
                      Apply Now
                    </a>
                  </h4>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </AnimationRevealPage>
  );
};
