import React from "react";
import { Helmet } from "react-helmet";
import ReCAPTCHA from "react-google-recaptcha";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Modal from "react-modal";
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import fbMarket from "images/fb-markerting.png";

export default () => {
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#121212";
  }

  function closeModal() {
    setIsOpen(false);
  }

  const imgCss = {
    width: "40%",
    margin: "0 auto",
    marginTop: "50px",
  };
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };
  function rccallback2806704000033917009() {
    if (document.getElementById("recap2806704000033917009") != undefined) {
      document
        .getElementById("recap2806704000033917009")
        .setAttribute("captcha-verified", true);
    }
    if (
      document.getElementById("recapErr2806704000033917009") != undefined &&
      document.getElementById("recapErr2806704000033917009").style.visibility ==
        "visible"
    ) {
      document.getElementById("recapErr2806704000033917009").style.visibility =
        "hidden";
    }
  }

  function reCaptchaAlert2806704000033917009() {
    var recap = document.getElementById("recap2806704000033917009");
    if (
      recap != undefined &&
      recap.getAttribute("captcha-verified") == "false"
    ) {
      document.getElementById("recapErr2806704000033917009").style.visibility =
        "visible";
      return false;
    }
    return true;
  }

  function validateEmail2806704000033917009() {
    var form = document.forms["WebToContacts2806704000033917009"];
    var emailFld = form.querySelectorAll("[ftype=email]");

    for (var i = 0; i < emailFld.length; i++) {
      var emailVal = emailFld[i].value;
      if (emailVal.replace(/^\s+|\s+$/g, "").length != 0) {
        var atpos = emailVal.indexOf("@");
        var dotpos = emailVal.lastIndexOf(".");
        if (atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= emailVal.length) {
          alert("Please enter a valid email address. ");
          emailFld[i].focus();
          return false;
        }
      }
    }
    return true;
  }

  function checkMandatory2806704000033917009() {
    var mndFileds = new Array("Last Name", "Email", "Phone", "CONTACTCF5");
    var fldLangVal = new Array(
      "Full\x20Name",
      "Email",
      "Mobile",
      "Company\x20Name"
    );
    for (var i = 0; i < mndFileds.length; i++) {
      var fieldObj =
        document.forms["WebToContacts2806704000033917009"][mndFileds[i]];
      if (fieldObj) {
        if (fieldObj.value.replace(/^\s+|\s+$/g, "").length == 0) {
          if (fieldObj.type == "file") {
            alert("Please select a file to upload.");
            fieldObj.focus();
            return false;
          }
          alert(fldLangVal[i] + " cannot be empty.");
          fieldObj.focus();
          return false;
        } else if (fieldObj.nodeName == "SELECT") {
          if (fieldObj.options[fieldObj.selectedIndex].value == "-None-") {
            alert(fldLangVal[i] + " cannot be none.");
            fieldObj.focus();
            return false;
          }
        } else if (fieldObj.type == "checkbox") {
          if (fieldObj.checked == false) {
            alert("Please accept  " + fldLangVal[i]);
            fieldObj.focus();
            return false;
          }
        }
        try {
          if (fieldObj.name == "Last Name") {
            var name = fieldObj.value;
          }
        } catch (e) {}
      }
    }
    trackVisitor2806704000033917009();
    if (!validateEmail2806704000033917009()) {
      return false;
    }

    if (!reCaptchaAlert2806704000033917009()) {
      return false;
    }
    document
      .querySelector(".crmWebToEntityForm .formsubmit")
      .setAttribute("disabled", true);
  }

  function tooltipShow2806704000033917009(el) {
    var tooltip = el.nextElementSibling;
    var tooltipDisplay = tooltip.style.display;
    if (tooltipDisplay == "none") {
      var allTooltip = document.getElementsByClassName("zcwf_tooltip_over");
      for (var i = 0; i < allTooltip.length; i++) {
        allTooltip[i].style.display = "none";
      }
      tooltip.style.display = "block";
    } else {
      tooltip.style.display = "none";
    }
  }

  var $zoho = $zoho || {};
  $zoho.salesiq = $zoho.salesiq || {
    widgetcode:
      "82497d4fb5fe6d4fd542d0183e11e76db122a1bf9a5945e27ac5ef4a65299b30",
    values: {},
    ready: function () {},
  };
  var d = document;
  var s = d.createElement("script");
  s.id = "zsiqscript";
  s.defer = true;
  s.src = "https://salesiq.zoho.com/widget";
  var t = d.getElementsByTagName("script")[0];
  t.parentNode.insertBefore(s, t);

  function trackVisitor2806704000033917009() {
    try {
      if ($zoho) {
        var LDTuvidObj =
          document.forms["WebToContacts2806704000033917009"]["LDTuvid"];
        if (LDTuvidObj) {
          LDTuvidObj.value = $zoho.salesiq.visitor.uniqueid();
        }
        var firstnameObj =
          document.forms["WebToContacts2806704000033917009"]["First Name"];
        if (firstnameObj) {
          var name = firstnameObj.value + " " + name;
        }
        $zoho.salesiq.visitor.name(name);
        var emailObj =
          document.forms["WebToContacts2806704000033917009"]["Email"];
        if (emailObj) {
          var email = emailObj.value;
          $zoho.salesiq.visitor.email(email);
        }
      }
    } catch (e) {}
  }
  return (
    <AnimationRevealPage disabled>
      <Helmet>
        <title>Facebook Marketing Services | 360 Expose</title>
        <meta
          name="description"
          content="360 Expose assists you in finding a target customer and making them aware you exist with brand awareness and brand marketing."
        />
      </Helmet>
      <Header />
      <section className="service-page mb-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h1 className="inner-main-heading">
                Facebook <span>Marketing</span>
              </h1>
              <p>
                Facebook is the most popular social media platform with more
                than a billion people using it every day. Facebook is a giant
                social media platform from where any kind of business can
                promote their business either free or pay. The Facebook page
                helps your business to connect with the targeted audience ona
                large scale. You can create a Facebook page from your account
                and start promoting the business for free but if you would like
                to target your audience with your business, you need to get a
                paid version of Facebook marketing for your business. You can
                get unlimited marketing options at a lower cost onthe Facebook
                platform. Either if you have a small business or large business,
                you can get your business to promote on Facebook and target
                audience to get more business and sales. Facebook's tools cater
                to the business that wants to form an authentic relationship
                with its audience. Facebook marketing is a platform that offers
                a variety of highly targeted paid advertisements and organic
                posts, allowing your brands to put on the top of the
                competitors.
              </p>
              <p>
                Below is the form which you can fill up and we will get back to
                you on how we can help you with the marketing and better
                strategies for your business marketing.
              </p>
              <p>
                Our Facebook Marketing strategy improves marketing on Facebook
                can help you efficiently reach all of the people who matter
                most.
              </p>

              <img className="mb-5" src={fbMarket} style={imgCss} />

              <p>
                To accomplish the above tasks properly and ensure that we have
                performed our audit as thoroughly as possible to provide you
                with the best results,Our marketing on Facebook can help you
                efficiently reach all of the people who matter most.
              </p>

              <h3>Ad Action Plan</h3>
              <h5>Ad Page</h5>

              <ul className="col-count-2 text-left">
                <li>Facebook Page Ad Set up</li>
                <li>Update Facebook Page if exist</li>
                <li>Search Engine Friendly Facebook Page</li>
              </ul>
              <h5>Content</h5>
              <ul className="col-count-2 text-left">
                <li>1st User Friendly Content</li>
                <li>Update Facebook Page Content if Exist</li>
                <li>Keyword Focusing Content</li>
              </ul>
              <h5> Target Audience</h5>
              <ul className="col-count-2 text-left">
                <li>Research, plan & strategy</li>
                <li>Demographics</li>
                <li>Behaviours</li>
                <li>Interest & Skills</li>
              </ul>
              <h5>Ad Campaign</h5>
              <ul className="col-count-2 text-left">
                <li>Goal/Objective ie Leads Generation/Visit/Message</li>
                <li>Message</li>
                <li>Strategy</li>
                <li>Ad Type</li>
                <li>Installation of Fb Pixel</li>
                <li>Tailored marketing strategy</li>
                <li>
                  Combination of many types of posts for more exposure and
                  engagement
                </li>
                <li>Customize social media post design</li>
                <li>Best #Hashtags</li>
                <li>Eye-catching content creator</li>
                <li>
                  Social Shares for free (not more than 3 and with a random
                  audience)
                </li>
                <li>Drive traffic organically to your website.</li>
              </ul>
              <h5>Ad Campaign Budget</h5>
              <ul className="col-count-2 text-left">
                Ad Budget
                <li>Ad Duration</li>
                <li>Reporting</li>
                <li>Ad copy & Technical Support.</li>
              </ul>

              <h3>Ad Campaign Launch</h3>
              <h5>Ad Monitor & Optimization</h5>
              <ul className="col-count-2 text-left">
                <li>Daily Budget</li>
                <li>Cost per Click</li>
                <li>Ad Frequency</li>
                <li>Result Rate</li>
                <li>Ad Guidelines</li>
                <li>Re-Targeting & Lookalike Audience.</li>
                <li>Brand Awareness & Engagement</li>
                <li>Lead generation Ads</li>
                <li>Ad Report</li>
                <li>
                  Conversion / Traffic For Shopify store or any eCommerce
                  website
                </li>
                <li>Increase in Business traffic & Leads. </li>
              </ul>
              <button onClick={openModal} className="btn-rounded mt-5 ">
                Order Now
              </button>
              <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
              >
                <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Order Now</h2>
                <button
                  style={{
                    position: "absolute",
                    top: "5px",
                    right: "20px",
                    fontSize: "25px",
                    color: "#c61919",
                  }}
                  onClick={closeModal}
                >
                  x
                </button>
                <div
                  id="crmWebToEntityForm"
                  class="zcwf_lblLeft crmWebToEntityForm"
                >
                  <form
                    action="https://crm.zoho.com/crm/WebToContactForm"
                    name="WebToContacts2806704000033917009"
                    method="POST"
                    onSubmit='javascript:document.charset="UTF-8"; return checkMandatory2806704000033917009()'
                    accept-charset="UTF-8"
                  >
                    <input
                      type="text"
                      style={{ display: "none" }}
                      name="xnQsjsdp"
                      value="ced1872ed3f0e7a57f85c65540721b18cdc993ff69dd8fd48681248af41ba5a1"
                    ></input>
                    <input
                      type="hidden"
                      name="zc_gad"
                      id="zc_gad"
                      value=""
                    ></input>
                    <input
                      type="text"
                      style={{ display: "none" }}
                      name="xmIwtLD"
                      value="6472df18ec48439af15916fc12450927840b1ede78ea83318627998c619776aa"
                    ></input>
                    <input
                      type="text"
                      style={{ display: "none" }}
                      name="actionType"
                      value="Q29udGFjdHM="
                    ></input>
                    <input
                      type="text"
                      style={{ display: "none" }}
                      name="returnURL"
                      value="https&#x3a;&#x2f;&#x2f;360expose.com&#x2f;thank-you"
                    ></input>

                    <input
                      type="text"
                      style={{ display: "none" }}
                      id="ldeskuid"
                      name="ldeskuid"
                    ></input>
                    <input
                      type="text"
                      style={{ display: "none" }}
                      id="LDTuvid"
                      name="LDTuvid"
                    ></input>

                    <div class="zcwf_row">
                      <div class="zcwf_col_lab">
                        <label for="Last_Name">
                          Full Name<span style={{ color: "red" }}>*</span>
                        </label>
                      </div>
                      <div class="zcwf_col_fld">
                        <input
                          type="text"
                          id="Last_Name"
                          name="Last Name"
                          maxlength="80"
                        ></input>
                        <div class="zcwf_col_help"></div>
                      </div>
                    </div>
                    <div class="zcwf_row">
                      <div class="zcwf_col_lab">
                        <label for="CONTACTCF5">
                          Company Name<span style={{ color: "red" }}>*</span>
                        </label>
                      </div>
                      <div class="zcwf_col_fld">
                        <input
                          type="text"
                          id="CONTACTCF5"
                          name="CONTACTCF5"
                          maxlength="255"
                        ></input>
                        <div class="zcwf_col_help"></div>
                      </div>
                    </div>
                    <div class="zcwf_row">
                      <div class="zcwf_col_lab">
                        <label for="Email">
                          Email<span style={{ color: "red" }}>*</span>
                        </label>
                      </div>
                      <div class="zcwf_col_fld">
                        <input
                          type="text"
                          ftype="email"
                          id="Email"
                          name="Email"
                          maxlength="100"
                        ></input>
                        <div class="zcwf_col_help"></div>
                      </div>
                    </div>
                    <div class="zcwf_row">
                      <div class="zcwf_col_lab">
                        <label for="Phone">
                          Mobile<span style={{ color: "red" }}>*</span>
                        </label>
                      </div>
                      <div class="zcwf_col_fld">
                        <input
                          type="text"
                          id="Phone"
                          name="Phone"
                          maxlength="50"
                        ></input>
                        <div class="zcwf_col_help"></div>
                      </div>
                    </div>
                    <div class="zcwf_row">
                      <div class="zcwf_col_lab">
                        <label for="CONTACTCF6">
                          Website &#x28;if any &#x29;
                        </label>
                      </div>
                      <div class="zcwf_col_fld">
                        <input
                          type="text"
                          id="CONTACTCF6"
                          name="CONTACTCF6"
                          maxlength="450"
                        ></input>
                        <div class="zcwf_col_help"></div>
                      </div>
                    </div>
                    <div class="zcwf_row">
                      <div class="zcwf_col_lab"></div>
                      <div class="zcwf_col_fld">
                        <ReCAPTCHA sitekey="6Ld8QpMgAAAAAOCy24CZgBx0C5uSvZPbeENKNVgg" />
                      </div>
                    </div>
                    <div class="zcwf_row">
                      <div class="zcwf_col_lab"></div>
                      <div class="zcwf_col_fld">
                        <input
                          type="submit"
                          id="formsubmit"
                          class="formsubmit zcwf_button"
                          value="Submit"
                          title="Submit"
                        />
                        <input
                          type="reset"
                          class="zcwf_button"
                          name="reset"
                          value="Reset"
                          title="Reset"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </AnimationRevealPage>
  );
};
